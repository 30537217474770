import { tv, VariantProps } from '@/utils/tv'

export const header = tv({
  slots: {
    base: ['w-full'],
    cigarbar: ['fixed top-0 z-[60]', 'w-full'],
    header: [
      'fixed left-0 top-[var(--cigar-bar-height)] z-50',
      'w-full',
      'bg-transparent'
    ],
    bar: [
      'max-w-7xl h-20',
      'mx-auto',
      'flex items-center justify-between',
      'px-6 lg:px-8'
    ],
    brandLink: ['flex items-center focus:outline-none'],
    brand: ['h-10 w-10 text-acqua-500'],
    nav: [
      'flex flex-row items-center justify-between gap-5',
      'w-full',
      'mobile:bg-black',
      'mobile:flex-col mobile:items-start mobile:gap-10',
      'mobile:absolute mobile:z-50 mobile:left-0 mobile:top-[calc(100%+160px)]',
      'mobile:pt-2 mobile:pb-10 mobile:px-6',
      'mobile:transition-all mobile:duration-300',
      'mobile:h-[calc(100vh_-_80px_-_var(--cigar-bar-height))]',
      'mobile:h-[calc(100dvh_-_80px_-_var(--cigar-bar-height))]'
    ],
    group: [
      'desktop:first-of-type:mx-auto',
      'desktop:first-of-type:rtl:-translate-x-10',
      'desktop:first-of-type:ltr:translate-x-10',

      'mobile:w-full',
      'mobile:relative',
      'mobile:first-of-type:overflow-y-auto',
      'mobile:first-of-type:ltr:pr-6',
      'mobile:first-of-type:rtl:pl-6',
      'mobile:first-of-type:pb-10',

      'mobile:last-of-type:before:content[""]',
      'mobile:last-of-type:before:absolute',
      'mobile:last-of-type:before:h-20',
      'mobile:last-of-type:before:z-50',
      'mobile:last-of-type:before:bg-gradient-to-t',
      'mobile:last-of-type:before:from-black',
      'mobile:last-of-type:before:pointer-events-none',
      'mobile:last-of-type:before:w-[calc(100%-10px)]',
      'mobile:last-of-type:before:bottom-[calc(100%+2rem)]',
      'tiny-scrollbar'
    ],
    list: [
      'flex items-center desktop:gap-4',
      'mobile:flex-col mobile:gap-6 mobile:items-start'
    ],
    item: ['relative w-full menu-desktop:mt-auto group'],
    itemStudio: ['lg:md-auto group relative w-full'],
    dropdown: [
      'flex flex-col gap-5',
      'pt-5',
      'desktop:w-[300px]',
      'desktop:absolute',
      'desktop:bg-black',
      'desktop:gap-2.5',
      'desktop:p-2',
      'desktop:rounded-xl',
      'desktop:shadow-lg',
      'desktop:left-1/2',
      'desktop:overflow-hidden',
      'desktop:z-10',
      'desktop:border-[1.5px]',
      'desktop:border-gray-200',
      'mobile:!flex mobile:!opacity-100 mobile:!transform-none'
    ],
    dropdownList: ['flex flex-col p-0 lg:p-2 gap-6 lg:gap-2.5'],
    dropdownLink: [
      'flex items-start gap-3',
      'text-gray-20 font-medium text-display-16 rounded-md',
      'lg:p-3',
      'transition-colors focus:outline-none',
      'lg:hover:bg-gray-500'
    ],
    icon: ['h-6 w-6 shrink-0 text-acqua-500']
  },
  variants: {
    opened: {
      false: {
        nav: ['mobile:translate-y-full']
      },
      true: {
        header: ['!bg-black'],
        nav: ['mobile:-translate-y-[160px]']
      }
    },
    blur: {
      true: {}
    },
  },
  compoundVariants: [
    {
      blur: true,
      opened: false,
      className: {
        header: ['bg-header', 'backdrop-blur-xl', 'border-b border-b-gray-500']
      }
    }
  ]
})

export const headerShare = tv({
  slots: {
    base: ['fixed z-40', 'w-full', 'bg-black'],
    content: ['flex justify-center', 'w-full', 'p-4'],
    progress: ['relative', 'h-1 w-full', 'bg-gray-100'],
    currentProgress: ['w-100 h-1', 'absolute inset-0 z-10', 'bg-gray-20']
  }
})

export type HeaderVariantReturnProps = ReturnType<typeof header>

export type HeaderVariantProps = Omit<
  VariantProps<typeof header>,
  'opened' | 'blur'
>
