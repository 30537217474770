'use client'

import NextLink from 'next/link'
import { Locale } from '@/config/locales'
import { getPathLocale } from '@/utils/locale'
import { Icon } from '@/components/ui/icon'

type FooterBrandProps = {
  locale: Locale
}

const FooterBrand = ({ locale }: FooterBrandProps) => {
  const href = getPathLocale(locale)

  return (
    <NextLink
      href={href}
      title="Moises AI"
      className="inline-flex rounded-sm outline-none focus:outline-offset-4 focus:outline-acqua-500"
    >
      <Icon name="brand_full" className="h-5 text-acqua-500" />
    </NextLink>
  )
}

export default FooterBrand
