'use client'

import { GlobalType } from '@/types'
import { motion } from 'framer-motion'
import { Button } from '../button'
import HeaderItem from './header.item'
import { HeaderVariantReturnProps } from './header.variants'
import useHeader from './use-header'
import useMobileOS from '@/hooks/use-mobile-os'

type HeaderNavProps = {
  styles: HeaderVariantReturnProps
  siteMenu?: GlobalType['navbarTop']['navbarItem']
  studioMenu?: GlobalType['navbarTop']['navbarItem']
}

const HeaderNav = ({
  styles,
  siteMenu = [],
  studioMenu = []
}: HeaderNavProps) => {
  const { onHoverDropdown, solidButton } = useHeader()
  const { mobileOS } = useMobileOS()
  const isMobile = ['ios','android'].includes(mobileOS || '')

  return (
    <nav id="header-nav" className={styles.nav()}>
      <div className={styles.group()}>
        <ul className={styles.list()}>
          {siteMenu.map(({ id, title, links }) => (
            <motion.li
              key={id}
              className={styles.item()}
              onMouseEnter={() => onHoverDropdown(`${id}`)}
              onMouseLeave={() => onHoverDropdown(false)}
            >
              <HeaderItem
                styles={styles}
                id={`${id}`}
                title={title}
                links={links}
              />
            </motion.li>
          ))}
        </ul>
      </div>
      <div className={styles.group()}>
        <ul className={styles.list()}>
          {studioMenu.map(({ id, title, links }, i) => {
            const isSolidButton = i === solidButton || i === 2
            let itemClassName = 'hidden'
            if(isMobile && i > 1) {
              itemClassName = 'flex lg:hidden'
            } else if(!isMobile && i <= 1) {
              itemClassName = 'flex'
            }

            return (
              <li key={id} className={styles.itemStudio({className: itemClassName})}>
                <Button
                  sectionName={`header_${title}`}
                  href={links[0].linkUrl}
                  title={title}
                  size="sm"
                  weight={isSolidButton ? 'medium' : 'medium'}
                  color={isSolidButton ? 'white' : 'whiteAcqua'}
                  appearance={isSolidButton ? 'solid' : 'ghost'}
                  tabIndex={0}
                  fluidOnMobile
                >
                  {title}
                </Button>
              </li>
            )
          })}
        </ul>
      </div>
    </nav>
  )
}

export default HeaderNav
